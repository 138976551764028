@import './_svg';

.header {
	position: relative;
	z-index: 11;
	background-color: #fff;
	& > ul {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
		justify-content: space-around;
		align-items: center;
		max-width: 1270px;
		margin: 0 auto;
		li {
			justify-self: center;
			&:first-of-type {
				padding: 1rem 0 0;
			}
			&:last-of-type {
				padding: 1rem 0;
			}
			a {
				font-size: 18px;
				font-weight: 700;
				color: #595959;
				display: block;
				text-align: center;
				letter-spacing: 1px;
			}
		}
	}
	.tel {
		margin: 0 0 .5rem;
		&::before {
			content: url('../assets/svg/tel-icon.svg');
			vertical-align: middle;
			margin-right: .5rem;
			margin-bottom: 1rem;
		}
	}
	.navCta {
		border-radius: 1.25rem;
		background-color: #595959;
		padding: 8px 20px;
		font-size: 16px;
		font-weight: 500;
		color: white;
		&:hover {
			opacity: .9;
		}
	}
}
.navbar {
	top: -1px;
	z-index: 10;
	width: 100%;
	background-color: #f0f1f0;
	& > div {
		position: relative;
		&[role="sticky"] {
			transition: all .9s ease;
			background-color: #fff;
			box-shadow: 0 0 18px rgba(0, 0, 0, .1);
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 10;
			animation: expand .9s;
			-webkit-animation: expand .9s;
			& + button {
				position: fixed;
				z-index: 10;
				animation: expand .9s;
				-webkit-animation: expand .9s;
			}
		}
	}
	& > div > ul {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 1270px;
		margin: 0 auto;
		padding: 0 1rem !important;
		& > li {
			height: 100%;
			padding: 1rem 0;
			position: relative;
			&:first-of-type {
				margin-right: auto;
			}
			a {
				color: #1c1c1c;
				font-size: 16px;
				font-weight: 600;
				letter-spacing: .06em;
				padding: 0 1rem;
				text-transform: uppercase;
				&::after {
					content: '';
					display: block;
					height: 3px;
					background-color: #bebbbc;
					transition: all .3s ease;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
					width: 0;
				}
			}
		}
	}
	& > button {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-50%, 60%);
		svg {
			color: #fff;
		}
	}
}

@media (max-width: 991px) {
	.navbar {
		& > div > ul {
			&[aria-expanded="false"]  {
				& > li:not(:first-of-type) {
					opacity: 0;
					visibility: hidden;
					max-height: 51px;
					width: 0;
					display: none;
				}
			}
			&[aria-expanded="true"] {
				opacity: 1;
				visibility: visible;
				flex-direction: column;
				& > li {
					width: 100%;
					padding: 1rem;
				}
				& + button {
					z-index: 1;
					&::after {
						content: '';
						position: absolute;
						left: auto;
						right: -20px;
						top: -15px;
						bottom: 0;
						width: calc(100vw + 1px);
						height: 100vh;
						padding-bottom: 100vh;
						z-index: -1;
					}
				}
			}
		}
		& > button {
			display: block;
		}
	}
}
@keyframes expand {
	0% {
		top: -60px;
		opacity: 0;
	}
	100% {
		top: 0;
		opacity: 1;
	}
}