.pip {
   position: relative;
   max-height: 500px;
   height: 100%;
   align-self: center;
   margin-bottom: 70px;
   & > div {
      &:first-of-type {
         position: absolute;
         width: 100%;
         max-width: 70%;
         min-width: 265px;
         height: 100%;
      }
      &:last-of-type {
         position: absolute;
         width: 60%;
         height: 75%;
         left: 105px;
         top: 150px;
         border: 8px solid white;
         border-radius: 20px;
      }
   }
}

.img-desktop {
   display: none;
   @media (min-width: 768px) {
      display: block;
   }
}
.img-mobile {
   display: none;
   @media (max-width: 767px) {
      display: block;
   }
}