.hero-content-center {
   height: 100%;
   min-height: calc(70vh + 1px);
   max-height: calc(70vh + 1px);
   position: relative;
   .overlay-dark {
      height: 100%;
      min-height: calc(70vh + 1px);
      max-height: calc(70vh + 1px);
      position: relative;
      img {
         border-radius: 0 0 5% 5%;
         object-position: center 65%;
      }
   }
   .hero-content-text {
      width: 100%;
      // max-width: 840px;
      padding: 1rem .5rem;
      color: #fff;
      position: absolute;
      top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      // text-align: center;
      left: 5%;
      text-align: left;
      max-width: 610px;
      transform: translate(0, -50%);
      h1 {
         font-size: calc(1rem + 2.8vmin);
         margin: 0 0 1rem;
      }
      h2 {
         font-size: calc(1rem + .4vmin);
         font-weight: 500;
         margin: 0 0 clamp(1.75rem, 4vw, 2.5rem);
         max-width: 80%;
         line-height: 1.5;
      }
      a {
         padding: 1rem 1.5rem;
         background-color: rgba(180, 4, 4, .6);;
         color: #fff;
         font-weight: 700;
         &:hover {
            background-color: rgba(180, 4, 4, 1);
         }
      }
   }
}
.hero-content-center-secondary {
   min-height: calc(30vh + 1px);
   position: relative;
   .overlay-dark {
      min-height: calc(30vh + 1px);
      position: relative;
      img {
         filter: brightness(.7);
         object-position: bottom center;
      }
   }
   .hero-content-text {
      width: 100%;
      max-width: 840px;
      padding: 1rem .5rem;
      color: #fff;
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      h1 {
         font-size: calc(1rem + 2.8vmin);
         margin: 0 0 1rem;
      }
      h2 {
         font-size: calc(1rem + .4vmin);
         font-weight: 500;
         margin: 0 0 clamp(1.75rem, 4vw, 2.5rem);
      }
      a {
         padding: 1rem 1.5rem;
         background-color: rgba(180, 4, 4, .6);;
         color: #fff;
         font-weight: 700;
         &:hover {
            background-color: rgba(180, 4, 4, 1);
         }
      }
   }
}
@media (max-width: 767px) {
   .hero-content-center {
      min-height: calc(55vh + 1px);
      .overlay-dark {
         min-height: calc(55vh + 1px);
         img {
            filter: brightness(.6);
         }
      }
   }
}

.hero-left-text-right-img {
   & > div {
      background-color: white;
      min-height: 60vh;
      max-width: 1270px;
      margin: 0 auto;
      border-radius: 25px;
      box-shadow: 0 0 10px rgba(0, 0, 0, .1);
      display: grid;
      grid-template-columns: 45% 55%;
      overflow: hidden;
      & > div {
         &:first-of-type {
            position: relative;
            overflow: hidden;
            &::before {
               content: '';
               background-color: white;
               position: absolute;
               display: block;
               width: 180px;
               height: 100%;
               left: 0;
               top: 0;
               transform: skew(-13deg) translateX(-50%);
               z-index: 2;
            }
            display: block;
            width: 100%;
            height: 100%;
            animation: zoom 30s infinite;
            transform-origin: top center;
            @keyframes zoom {
               0% {
                 transform: scale(1);
               }
               50% {
                 transform: scale(1.2);
               }
               100% {
                 transform: scale(1);
               }
            }
            img {
               z-index: 1;
               border-radius: 0 20px 20px 0;
               transition: all 2s ease-in-out;
               &[aria-label="true"] {
                  opacity: 1;
                  visibility: visible;
               }
               &:not([aria-label]) {
                  opacity: 0;
                  visibility: hidden;
               }
            }
         }
         &:last-of-type {
            order: -1;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            justify-content: center;
            align-items: flex-start;
            max-width: 85%;
            margin: 0 auto;
            z-index: 1;
            h1 {
               font-size: calc(1rem + 2vmin);
               font-weight: 600;
               text-transform: capitalize;
               line-height: 1.5;
            }
            p {
               &:first-of-type {
                  margin: 30px 0;
                  font-size: calc(1rem + 1vmin);
                  line-height: 1.3;
               }
            }
         }
      }
   }
   @media (max-width: 767px) {
      & > div {
         grid-template-columns: 1fr;
         grid-template-rows: 50vh auto;
         border-radius: 0;
         & > div {
            &:first-of-type {
               order: -1;
               animation: none;
               img {
                  border-radius: 0;
                  object-position: 5% center;
               }
               &::before {
                  content: none;
               }
            }
            &:last-of-type {
               padding: 3rem 1rem;
            }
         }
      }
   }
}