.contact-form {
   display: flex;
   flex-direction: column;
   &>span {
      position: relative;
      label {
         position: absolute;
         pointer-events: none;
         user-select: none;
         color: rgba(0, 0, 0, .5);
         top: 10px;
         left: 10px;
         &.float {
            transform: translate(-20%, -30%) scale(.6);
         }
      }
      input {
         width: 100%;
      }
      &:has(input:focus) {
         label {
            transform: translate(-20%, -30%) scale(.6);
         }
      }
   }
   input, textarea {
      margin-bottom: 1rem;
      border: 1px solid rgba(0, 0, 0, .2);
      border-radius: 4px;
      min-height: 56px;
      padding: .75rem .5rem 0 .5rem;
      &::placeholder {
         color: rgba(0, 0, 0, .5);
      }
   }
   &>p:last-child {
      margin: 1rem 0;
   }
}