@import './_svg';

main {
   background-color: #f0f1f0;
   .hero {
      &.slider,
      &.center-single-image {
         max-height: 60vh;
         min-height: 500px;
         height: 100%;
         width: 100%;
         position: relative;
         margin-bottom: 2rem;
      }
      &.slider {
         & > div:first-of-type {
            & > div:first-of-type {
               display: flex;
               min-height: 58vh;
               position: relative;
               overflow: hidden;
               & > div {
                  display: block;
                  transition: transform .6s ease-in-out;
                  perspective: 1000px;
                  backface-visibility: hidden;
                  &.active {
                     left: 0;
                     transform: translate3d(0, 0, 0);
                  }
                  & > div {
                     width: 100%;
                     height: 100%;
                     min-height: 425px;
                     position: relative;
                     img {
                        object-fit: cover;
                        width: 100% !important;
                     }
                  }
               }
            }
         }
         @media (min-width: 768px) {
            [media="mobile"] {
               display: none !important;
            }
         }
         @media (max-width: 767px) {
            [media="desktop"] {
               display: none !important;
            }
            & > div {
               button {
                  width: 48px;
                  padding: 7px;
               }
            }
         }
      }
      &.center-single-image {
         box-shadow: 0 0 10px 2px rgba(0, 0, 0, .2);
      }
      &.center-text {
         max-width: 700px;
         margin: 0 auto;
         padding: 2rem 1rem;
         text-align: center;
         h1 {
            text-transform: uppercase;
            color: green;
            margin: 0 0 1rem;
            font-weight: 700;
         }
         .title {
            font-size: var(--headerFontSize);
            margin: 0 0 1rem;
         }
         p {
            font-size: var(--bodyFontSize);
            line-height: 1.5em;

         }
      }
   }
   .main-content {
      max-width: 1270px;
      min-height: 1000px;
      margin: 0 auto;
      padding: 0 1rem;
      padding-bottom: 1rem;
      section {
         display: block;
         width: 100%;
         height: 100%;
         padding: 1rem;
         margin-bottom: 2rem;
         & > header {
            font-size: calc(1rem + .5vmin);
            margin-bottom: 2rem;
         }
         & > article {
            margin: 2rem 0;
            & > h2 {
               font-size: calc(1rem + .5vmin);
               font-weight: 500;
               margin-bottom: 1rem;
            }
            & > h3 {
               font-size: calc(1rem + .25vmin);
               font-weight: 500;
               margin-bottom: .5rem;
            }
            & > p {
               font-size: 16px;
               font-weight: 400;
               letter-spacing: 1px;
               margin-bottom: 1rem;
               color: #595959;
            }
            & > ul {
               li {
                  letter-spacing: 1px;
                  margin-bottom: 1rem;
                  margin-left: 1.5rem;
               }
            }
         }
      }
   }
   .gallery-standard {
      & > div {
         display: grid;
         grid-template-columns: repeat(auto-fit, minmax(300px, 305px));
         grid-gap: clamp(1rem, 1.5vw, 1.25rem);
         justify-content: center;
         & > div {
            position: relative;
            height: 400px;
            border-radius: 20px;
            a img {
               border-radius: 1.25rem;
               object-fit: cover;
               object-position: center;
            }
         }
      }
   }
   
}
.pswp__container{
   max-width: 75vw;
   max-height: 75vh;
   top: 50% !important;
   left: 50% !important;
   transform: translate(-50%, -50%) !important;
   img {
      width: 75vw !important;
      height: 75vh !important;
   }
}

[class*="pswp__button--arrow"] {
   display: none !important;
}

.navLink {
   position: relative;
   -webkit-transition: all 0.3s ease;
   -o-transition: all 0.3s ease;
   transition: all 0.3s ease;
   width: 100%;
   max-width: 100px;
   &:focus::after, &:hover::after {
      width: 100%;
   }
   &::after {
      content: "";
      display: block;
      margin-top: 0.875rem;
      height: 3px;
      background-color: #bebbbc;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
   }
}

@media (max-width: 767px) {
	main {
		.main-content {
         padding: 0 .5rem;
         section {
            padding: 1rem .5rem;
         }
      }
	}
   .pswp__container{
      max-width: 95vw;
      img {
         width: 95vw !important;
      }
   }
}