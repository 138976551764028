.content-right {
   & > h1 {
      text-align: center;
      text-transform: uppercase;
      font-size: 28px;
      font-weight: 400;
      margin-top: 3rem;
   }
   & > p {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #595959;
      letter-spacing: 1px;
   }
   & > article {
      display: grid !important;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      grid-gap: 2rem;
      margin-top: 3rem;
      & > div {
         &:first-of-type {
            position: relative;
            border-radius: 20px;
            min-height: 700px;
            &::after {
               content: '';
               display: block;
               position: absolute;
               top: 0;
               left: 0;
               width: 100%;
               height: 100%;
               background-color: lightgrey;
               border-radius: 20px;
               z-index: 0;
               transform: scale(.85) rotate(10deg);
            }
            img {
               border-radius: 20px;
               object-fit: cover;
               transform: scale(.8);
               box-shadow: 0 0 10px rgba(0, 0, 0, .2);
               z-index: 1;
            }
         }
         &:last-of-type {
            letter-spacing: 1.25px;
            line-height: 1.7;
            h1 {
               font-size: calc(1rem + .5vmin);
               font-weight: normal;
               margin-bottom: 1rem;
               strong {
                  font-size: inherit;
               }
            }
            ul {
               padding: 0 0 0 2rem;
               li {
                  list-style: disc;
                  margin: 0 0 1rem;
               }
            }
            p {
               margin: 0 0 1rem;
            }
         }
      }
   }
   @media (max-width: 767px) {
      & > article {
         grid-template-columns: 1fr !important;
         & > div {
            &:first-of-type {
               min-height: 300px;
               max-height: 300px;
               img {
                  object-position: center;
               }
            }
         }
      }
   }
}

.three-card {
   display: block;
   width: 100%;
   height: 100%;
   & > h1 {
      text-align: center;
      text-transform: uppercase;
      font-size: 28px;
      font-weight: 400;
      margin-top: 3rem;
   }
   & > p {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #595959;
      letter-spacing: 1px;
   }
   & > article {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 2rem;
      margin: 1rem 0;
      width: 100%;
      height: 100%;
      & > div {
         background-color: #fafafa;
         padding: 1rem;
         border-radius: 2px;
         box-shadow: 0 0 3px rgba(0, 0, 0, .1);
         transition: all 0.3s linear;
         -webkit-transition: all 0.3s linear;
         height: 100%;
         width: 100%;
         &:nth-of-type(1) {
            &::before {
               content: url('data:image/svg+xml, ' + $headset-black);
            }
         }
         &:nth-of-type(2) {
            &::before {
               content: url('data:image/svg+xml, ' + $strategy-black);
            }
         }
         &:nth-of-type(3) {
            &::before {
               content: url('data:image/svg+xml, ' + $integration-black);
            }
         }
         & > p {
            &:first-of-type {
               font-size: 22px;
               font-weight: 500;
               padding-top: 1rem;
               padding-bottom: .5rem;
            }
            &:nth-of-type(2) {
               font-size: 14px;
               font-weight: 400;
               padding-bottom: .5rem;
               color: #595959;
            }
            &:last-of-type {
               font-size: 16px;
               color: #595959;
               line-height: 1.5;
            }
         }
         &:hover {
            box-shadow: 0 5px 20px rgb(0, 0, 0, .3);
            background-color: #bc2227;
            color: #fff !important;
            &:nth-of-type(1) {
               &::before {
                  content: url('data:image/svg+xml, ' + $headset-white);
               }
            }
            &:nth-of-type(2) {
               &::before {
                  content: url('data:image/svg+xml, ' + $strategy-white);
               }
            }
            &:nth-of-type(3) {
               &::before {
                  content: url('data:image/svg+xml, ' + $integration-white);
               }
            }
            & > p {
               color: #fff;
            }
         }
      }
   }
}

.grid-cube {
   & > h1 {
      text-align: center;
      text-transform: uppercase;
      font-size: 28px;
      font-weight: 400;
      margin-bottom: 1rem;
   }
   & > p {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #595959;
      margin-bottom: 3rem;
      letter-spacing: 1px;
   }
   & > article {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 3rem 2rem;
      & > div {
         padding: 1rem 1rem 1rem 0;
         display: flex;
         flex-direction: column;
         width: 100%;
         height: 100%;
         & > p {
            &:first-of-type {
               font-size: 20px;
               margin-bottom: 1rem;
               font-weight: 500;
            }
            &:last-of-type {
               font-size: 16px;
               font-weight: 400;
               line-height: 1.5;
               color: #595959;
               letter-spacing: 1px;
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               height: 100%;
            }
         }
      }
   }
}

.full-width {
   position: relative;
   padding: 2rem 1rem 4rem !important;
   margin-bottom: 0 !important;
   &::after {
      content: '';
      width: 100vw;
      height: 100%;
      background-color: #eee;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin-left: calc(-50vw + 50%);
      margin-right: calc(-50vw + 50%);
      z-index: -1;
   }
   & > h1 {
      text-transform: uppercase;
      font-size: 28px;
      font-weight: 400;
      margin-bottom: 1rem;
   }
   & > p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #595959;
      margin-bottom: 3rem;
      letter-spacing: 1px;
   }
}

.sbs-form {
   margin: 3rem 0;
   & > article {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      justify-content: center;
      grid-gap: 2rem;
      margin: 1rem 0;
      width: 100%;
      height: 100%;
      & > div {
         &:first-of-type {
            h1 {
               font-size: calc(1rem + 1.9vmin);
               margin-bottom: 2rem;
            }
            h2 {
               font-size: calc(1rem + .6vmin);
               font-weight: 400;
               margin-bottom: 2rem;
            }
            form {
               background-color: white;
               border-radius: 10px 10px 0 0;
               padding: 2rem 1rem;
               box-shadow: 0 0 8px 1px rgba(0, 0, 0, .2);
               z-index: 10;
               display: flex;
               flex-direction: column;
               position: relative;
               & > span {
                  position: relative;
                  label {
                     position: absolute;
                     pointer-events: none;
                     user-select: none;
                     color: rgba(0, 0, 0, .5);
                     top: 10px;
                     left: 10px;
                     &[data-style="float"] {
                        transform: translate(-20%, -30%) scale(.6);
                     }
                  }
                  input {
                     width: 100%;
                  }
                  &:has(input:focus) {
                     label {
                        transform: translate(-20%, -30%) scale(.6);
                     }
                  }
               }
               input, textarea {
                  margin-bottom: 1rem;
                  border: 1px solid rgba(0, 0, 0, .2);
                  border-radius: 4px;
                  min-height: 56px;
                  padding: .75rem .5rem 0 .5rem;
                  &::placeholder {
                     color: rgba(0, 0, 0, .5);
                  }
               }
               & > p:last-child {
                  margin: 1rem 0;
               }
            }
         }
         &:last-of-type {
            background-color: grey;
            border-radius: 5px;
            padding: 2rem 1rem;
            align-self: center;
            max-width: 320px;
            & > div {
               color: #fff;
               a {
                  color: #fff;
               }
               & > p {
                  &:first-of-type {
                     font-size: calc(1rem + 2vmin);
                     margin-bottom: 1rem;
                     &::before {
                        content: '';
                        border-left: 10px solid rgba(180, 4, 4, 1);
                        margin-right: 1rem;
                     }
                  }
                  &:last-of-type {
                     margin: 0 0 1rem 1.75rem;
                     font-size: calc(1rem + .5vmin);
                     a {
                        font-size: calc(1rem + .5vmin);
                     }
                  }
               }
            }
         }
      }
   }
}

.google-maps {
   border-radius: 20px;
}

.sbs-banner {
   background-color: #575757;
   border-radius: 20px;
   color: #fff;
   & > article {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      grid-gap: 2rem;
      justify-content: space-around;
      align-items: center;
      margin: 2rem !important;
      & > div {
         &:first-of-type {
            h1 {
               font-size: calc(1rem + .75vmin);
               font-weight: 500;
               margin-bottom: 1rem;
            }
         }
         &:last-of-type {
            text-align: right;
            max-width: 60%;
            margin-left: auto;
         }
      }
   }
}

.full-content {
   margin: 3rem 0;
   & > header {
      font-size: calc(1rem + 2vmin) !important;
      font-weight: 700;
      text-transform: uppercase;
   }
   p, li {
      line-height: 1.7;
   }
}

.full-content-list {
   display: block;
   width: 100%;
   height: 100%;
   & > h1 {
      text-align: center;
      text-transform: uppercase;
      font-size: 28px;
      font-weight: 400;
      margin-top: 3rem;
      margin-bottom: 1rem;
   }
   & > article {
      & > ul {
         li {
            background-color: white;
            border-radius: 10px;
            padding: 1rem calc(1rem + 25px) 1rem 1rem;
            cursor: pointer;
            position: relative;
            transition: all 3s ease-in-out;
            & > p {
               &:last-of-type {
                  opacity: 0;
                  visibility: hidden;
                  height: 0;
               }
            }
            &[aria-expanded="true"] {
               & > p:first-of-type {
                  margin-bottom: 1rem;
                  font-weight: 600;
               }
               & > p:last-of-type {
                  opacity: 1;
                  visibility: visible;
                  height: auto;
                  margin-left: 1rem;
               }
               &::after {
                  content: '-';
               }
            }
            &[aria-expanded="false"],
            &:not([aria-expanded="true"]) {
               &::after {
                  content: '+';
               }
            }
            &::after {
               font-weight: 900;
               position: absolute;
               top: 0;
               right: 0;
               border: 1px solid black;
               border-radius: 50%;
               width: 20px;
               height: 20px;
               transform: translate(-50%, 50%);
               display: flex;
               justify-content: center;
               align-items: center;
            }
         }
      }
   }
}

.full-bg-white {
   position: relative;
   z-index: 0;
   &::after {
      content: '';
      width: 100vw;
      height: 100%;
      background-color: white;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin-left: calc(-50vw + 50%);
      margin-right: calc(-50vw + 50%);
      z-index: -1;
   }
}