@import './_svg';

.footer {
	position: relative;
	// min-height: 400px;
	.container {
		display: grid;
		column-gap: 2rem;
		max-width: 1270px;
		padding: 1rem 2rem;
		margin: 0 auto;
		color: #fff;
		a {
			color: #fff;
		}
		li {
			padding: .5rem 0;
		}
		li, li > a {
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 2px;
			padding-bottom: .5rem;
		}
		li > a {
			white-space: pre;
		}
		&::before {
			content: '';
			background-color: #3d3d3d;
			width: 100vw;
			position: absolute;
			top: 0;
			bottom: 0;
			margin-left: calc(-50vw + 50%);
			margin-right: calc(-50vw + 50%);
			left: 50%;
			right: 50%;
			transform: translateX(-50%);
			z-index: -1;
		}
	}
	.copyright {
		background-color: black;
		color: white;
		text-align: center;
		padding: .5rem;
		
	}
}
@media screen and (max-width: 767px) {
	.footer {
		.container {
			grid-template-columns: 1fr;
			& > div {
				margin: 1rem 0 1rem;
			}
		}
	}
}
@media screen and (min-width: 768px) {
	.footer {
		.container {
			grid-template-columns: 1.5fr 1fr 1fr;
		}
	}
}