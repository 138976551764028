.visually-hidden {
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
	padding: 0;
	border: 0;
	height: 1px;
	width: 1px;
	overflow: hidden;
	white-space: nowrap;
}
.pageNotFound {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	height:100vh;
}
.full-bg-light {
	position: relative;
	&::after {
		content: '';
		position: absolute;
		width: 100vw;
		height: 100%;
		background-color: #f7f7f7;
		top: 0;
		left: 0;
		margin-left: calc(-50vw + 50%);
		margin-right: calc(-50vw + 50%);
		z-index: -1;
	}
}