@import './_svg';
@import './_templates';
@import './_buttons';
@import './_misc';
@import './_heroes';
@import './_contents';
@import './_images';
@import './_forms';

@font-face {
	font-family: 'Inter';
	src: url('../fonts/roboto-regular.ttf');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Righteous';
	src: url('../fonts/righteous-regular.ttf');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
* {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 16px;
	vertical-align: baseline;
	background: transparent;
	box-sizing: border-box;
}
:root,
html {
	overflow-x: hidden;
	--primary: #f44c1c;
	--primaryLight: #ffba43;
	--secondary: #ffba43;
	--secondaryLight: #ffba43;
	--headerColor: #1a1a1a;
	--bodyTextColor: #4e4b66;
	--bodyTextColorWhite: #fafbfc;
	--heroFontSize: clamp(2.4375rem, 6.4vw, 3.8125rem);
	--topperFontSize: clamp(0.8125rem, 1.6vw, 1rem);
	--headerFontSize: clamp(1.9375rem, 3.9vw, 3.0625rem);
	--bodyFontSize: 1rem;
	--sectionPadding: clamp(3.75rem, 7.82vw, 6.25rem) 1rem;
	--gridGap: clamp(1rem, 1.5vw, 1.25rem);
}
body {
	font-family: 'Roboto', sans-serif;
	img {
		width: 100%;
		max-width: 100%;
		object-fit: cover;
	}
	a {
		text-decoration: none;
		display: inline-block;
		&:visited {
			color: blue;
		}
	}
	button {
		border: none;
		background: none;
		margin: 0;
		padding: 0;
		cursor: pointer;
		text-decoration: none;
		font-family: Montserrat, Robboto, sans-serif;
		font-size: 16px;
	}
	li {
		list-style: none;
	}
	input:not([type='submit']) {
		border: 1px solid black;
	}
	hr {
		height: 1px;
		background-color: #f2f2f2;
		margin: 1rem 0;
	}
	h2 {
		font-size: calc(1rem + 1.7vmin);
	}
}
